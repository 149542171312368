import { faMinus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Package } from '../../../Classes/Package';
import * as styles from './ComparePackages.module.scss';
import { getFeatureList } from '../../../Classes/FeatureContent';
import { Feature } from '../Feature/Feature';
import { Link } from 'gatsby';

type Block = {
  Title: string;
  Features: Feature[];
  Bronze: any;
  Silver: any;
  Gold: any;
  isConsultant: number;
}

interface Props {
  Packages: Package[];
}

const ComparePackages: React.FC<Props> = ({ Packages }) => {
  const [tab, setTab] = useState<number>(0);
  const FeatureList = getFeatureList(Packages);

  return (
    <>
      <div className={styles.Section}>
        <h1>Compare Packages</h1>
        <p>Compare each of our packages to find the best one for you. From our FREE Bronze package to our Gold Package which has everything a growing business needs to start their HR Journey! Each package is designed to keep your business in the know for any changes and updates to the HR world so you can focus on growing your business.</p>

        <div className={styles.Tabs}>
          <div className={styles.TabsInner}>
            <button onClick={() => setTab(0)} className={tab === 0 ? styles.Active : ""}>
              Business
            </button>
            <button onClick={() => setTab(1)} className={tab === 1 ? styles.Active : ""}>
              Consultant
            </button>
          </div>
        </div>

        <div className={styles.Features}>
          {
            FeatureList.filter(x => x.isConsultant === tab).map((Block: Block, i: number) => {
              return <div className={styles.SectionBlock}>
                {i ? <h2>
                  <span>{Block.Title}</span>
                  <b>{Block.Bronze ? Block.Bronze : <i className={styles.Minus}><FontAwesomeIcon icon={faMinus} /></i>}</b>
                  <b>{Block.Silver ? Block.Silver : <i className={styles.Minus}><FontAwesomeIcon icon={faMinus} /></i>}</b>
                  <b>{Block.Gold ? Block.Gold : <i className={styles.Minus}><FontAwesomeIcon icon={faMinus} /></i>}</b>
                </h2> : <h1>
                  <span>{Block.Title}</span>
                  <b>{Block.Bronze}</b>
                  <b>{Block.Silver}</b>
                  <b>{Block.Gold}</b>
                </h1>}
                <ul>
                  {
                    Block.Features.map((feature: Feature) => {
                      return <li>
                        <Link to={`/Feature/${encodeURIComponent(feature.Title)}`}>
                          <i>
                            <FontAwesomeIcon icon={faPlusCircle} />
                          </i>
                          <span>{feature.Title}</span>
                        </Link>
                      </li>
                    })
                  }
                </ul>
              </div>
            })
          }
        </div>
      </div >
    </>
  );
};

export default ComparePackages;
