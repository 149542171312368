// extracted by mini-css-extract-plugin
export var Active = "ComparePackages-module--Active--4518d";
export var Features = "ComparePackages-module--Features--d683e";
export var Image = "ComparePackages-module--Image--8e16d";
export var Minus = "ComparePackages-module--Minus--c0245";
export var Modal = "ComparePackages-module--Modal--b0331";
export var Section = "ComparePackages-module--Section--9cb8a";
export var SectionBlock = "ComparePackages-module--SectionBlock--f784e";
export var Tabs = "ComparePackages-module--Tabs--64e8d";
export var TabsInner = "ComparePackages-module--TabsInner--15d1c";
export var Text = "ComparePackages-module--Text--8e1a9";