import React from "react";
import { Package } from "./Package";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

export const getFeatureList = (Packages: Package[]) => {
    return [{
        Title: "Feature",
        Bronze: "Bronze",
        Silver: "Silver",
        Gold: "Gold",
        Features: [],
        isConsultant: 0
    }, {
        Title: "Document Templates",
        Bronze: <>
            <small>FREE</small>
            {Packages.find(p => p.ID === 1)?.Documents.length} Templates
        </>,
        Silver: <>
            <small>An Additional</small>
            {(Packages.find(p => p.ID === 2)?.Documents as Document[]).length} Templates
        </>,
        Gold: <>
            <small>An Additional</small>
            {(Packages.find(p => p.ID === 3)?.Documents as Document[]).length} Templates
        </>,
        Features: [],
        isConsultant: 0
    }, {
        Title: "Employee Records",
        Bronze: <>
            <small>Up To</small>
            5 Employees
        </>,
        Silver: <>
            <small>Up To</small>
            15 Employees
        </>,
        Gold: <>
            <small>Up To</small>
            25 Employees
        </>,
        isConsultant: 0,
        Features: [{
            Title: "Employee Information",
            Description: <>
                <h2>Employee Information Feature: Secure Personal Data Management</h2>
                <p>
                    The "Employee Information" feature of our HR portal serves as a central repository for securely managing personal data of employees. With robust encryption and access controls, this feature ensures the confidentiality and integrity of sensitive information, adhering strictly to compliance standards and data protection regulations. Employees can conveniently update their personal details, such as contact information, emergency contacts, and banking details, while administrators have granular control over who can view or modify specific data fields, safeguarding privacy and confidentiality.
                </p>
                <h2>Efficient Data Accessibility and Management</h2>
                <p>
                    Streamlining HR operations, the "Employee Information" feature facilitates seamless access to crucial employee data, enhancing efficiency and productivity. Through intuitive user interfaces and search functionalities, HR personnel can swiftly retrieve necessary information for payroll processing, performance evaluations, and compliance reporting. Additionally, customizable fields accommodate diverse organizational needs, enabling tailored data collection and analysis to support strategic decision-making and resource allocation.
                </p>
                <h2>Comprehensive Audit Trails and Compliance Assurance</h2>
                <p>
                    Maintaining accountability and compliance integrity, the "Employee Information" feature provides comprehensive audit trails and activity logs, documenting every interaction with employee data. Administrators can track modifications, access history, and system activities, ensuring transparency and accountability in data management practices. Moreover, built-in compliance checks and reminders assist in adhering to regulatory requirements, mitigating risks associated with data breaches or non-compliance, and fostering trust and confidence among employees and stakeholders in the HR portal's data security measures.
                </p>
            </>,
            Image: "",
        }, {
            Title: "Flexible Working Tracking",
            Description: "",
            Image: "",
        }, {
            Title: "Payroll Journal",
            Description: "",
            Image: "",
        }, {
            Title: "Absence Management",
            Description: "",
            Image: "",
        }, {
            Title: "Reference Requesting",
            Description: "",
            Image: "",
        }, {
            Title: "Salary Management",
            Description: "",
            Image: "",
        }, {
            Title: "Company Calendar",
            Description: "",
            Image: "",
        }, {
            Title: "Perks & Benefits",
            Description: "",
            Image: "",
        }, {
            Title: "Asset Management",
            Description: "",
            Image: "",
        }, {
            Title: "Onboarding Procedure",
            Description: "",
            Image: "",
        }, {
            Title: "Offboarding Procedure",
            Description: "",
            Image: "",
        }, {
            Title: "Mood Monitoring",
            Description: "",
            Image: "",
        }, {
            Title: "Task & Goal Managment",
            Description: "",
            Image: "",
        }, {
            Title: "Training Resources",
            Description: "",
            Image: "",
        }]
    }, {
        Title: "Document Storage",
        Bronze: <>
            <small>Up To</small>
            250MB
        </>,
        Silver: <>
            <small>Up To</small>
            1GB
        </>,
        Gold: <>
            <small>Up To</small>
            3GB
        </>,
        Features: [],
        isConsultant: 0
    }, {
        Title: "Recruitment",
        Bronze: <>
            <small>Up To</small>
            3 Live Adverts
        </>,
        Silver: <>
            <small>Up To</small>
            5 Live Adverts
        </>,
        Gold: <>
            <small>Up To</small>
            10 Live Adverts
        </>,
        Features: [],
        isConsultant: 0
    }, {
        Title: "Employee Portal",
        Bronze: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        Silver: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        Gold: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        isConsultant: 0,
        Features: [{
            Title: "Employee Information",
            Description: "",
            Image: "",
        }, {
            Title: "Flexible Working Tracking",
            Description: "",
            Image: "",
        }, {
            Title: "Absence Reporting",
            Description: "",
            Image: "",
        }, {
            Title: "Team & Department Manager view",
            Description: "",
            Image: "",
        }, {
            Title: "Onboarding Procedure",
            Description: "",
            Image: "",
        }, {
            Title: "Mood Reporting",
            Description: "",
            Image: "",
        }]
    }, {
        Title: "Technical Support",
        Bronze: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        Silver: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        Gold: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        isConsultant: 0,
        Features: [{
            Title: "Telephone Support",
            Description: "",
            Image: "",
        }, {
            Title: "Email Support",
            Description: "",
            Image: "",
        }
            // , {
            //   Title: "Live Chat Support",
            //   Description: "",
            //   Image: "",
            // }
        ]
    }, {
        Title: "Policy Manager",
        Bronze: "",
        Silver: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        Gold: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        isConsultant: 0,
        Features: []
    }, {
        Title: "Probation Reviews",
        Bronze: "",
        Silver: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        Gold: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        isConsultant: 0,
        Features: []
    }, {
        Title: "Peer Reviews",
        Bronze: "",
        Silver: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        Gold: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        isConsultant: 0,
        Features: []
    }, {
        Title: "Feature",
        Bronze: "Bronze",
        Silver: "",
        Gold: "Gold",
        Features: [],
        isConsultant: 1
    }, {
        Title: "Document Templates",
        Bronze: <>
            <small>FREE</small>
            {Packages.find(p => p.ID === 1)?.Documents.length} Templates
        </>,
        Silver: <></>,
        Gold: <>
            <small>An Additional</small>
            {(Packages.find(p => p.ID === 2)?.Documents as Document[]).length + (Packages.find(p => p.ID === 3)?.Documents as Document[]).length} Templates
        </>,
        Features: [],
        isConsultant: 1
    }, {
        Title: "Customer Management",
        Bronze: <>
            <small>FREE</small>
            3 Clients
        </>,
        Silver: <></>,
        Gold: <>
            10 Clients
            {/* <small>See <Link to="">store</Link> for additional licenses</small> */}
        </>,
        Features: [{
            Title: "Payroll Journal",
            Description: "",
            Image: "",
        }, {
            Title: "Reference Requesting",
            Description: "",
            Image: "",
        }, {
            Title: "Salary Management",
            Description: "",
            Image: "",
        }, {
            Title: "Policy Manager",
            Description: "",
            Image: "",
        }, {
            Title: "Probation Reviews",
            Description: "",
            Image: "",
        }, {
            Title: "Peer Reviews",
            Description: "",
            Image: "",
        }, {
            Title: "Perks & Benefits",
            Description: "",
            Image: "",
        }, {
            Title: "Asset Management",
            Description: "",
            Image: "",
        }, {
            Title: "Onboarding Procedure",
            Description: "",
            Image: "",
        }, {
            Title: "Offboarding Procedure",
            Description: "",
            Image: "",
        }, {
            Title: "Mood Monitoring",
            Description: "",
            Image: "",
        }, {
            Title: "Task & Goal Managment",
            Description: "",
            Image: "",
        }, {
            Title: "Training Resources",
            Description: "",
            Image: "",
        }],
        isConsultant: 1
    }, {
        Title: "Document Storage",
        Bronze: <>
            Up To 100MB
            <small>Per Client</small>
        </>,
        Silver: <></>,
        Gold: <>
            Up To 500MB
            <small>Per Client</small>
        </>,
        Features: [],
        isConsultant: 1
    }, {
        Title: "Recruitment",
        Bronze: <>
            1 Live Advert
            <small>Per Client</small>
        </>,
        Silver: <></>,
        Gold: <>
            5 Live Adverts
            <small>Per Client</small>
        </>,
        Features: [],
        isConsultant: 1
    }, {
        Title: "PrimeHR Lite",
        Bronze: <>
            5 Employees
            <small>Per Client</small>
        </>,
        Silver: <></>,
        Gold: <>
            15 Employees
            <small>Per Client</small>
        </>,
        isConsultant: 1,
        Features: [{
            Title: "Employee Information",
            Description: "",
            Image: "",
        }, {
            Title: "Flexible Working Tracking",
            Description: "",
            Image: "",
        }, {
            Title: "Absence Management",
            Description: "",
            Image: "",
        }, {
            Title: "Company Calendar",
            Description: "",
            Image: "",
        }, {
            Title: "Onboarding Procedure",
            Description: "",
            Image: "",
        }, {
            Title: "Mood Reporting",
            Description: "",
            Image: "",
        }]
    }, {
        Title: "Technical Support",
        Bronze: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        Silver: <></>,
        Gold: <i>
            <FontAwesomeIcon icon={faCheckCircle} />
        </i>,
        isConsultant: 1,
        Features: [{
            Title: "Telephone Support",
            Description: "",
            Image: "",
        }, {
            Title: "Email Support",
            Description: "",
            Image: "",
        }
            // , {
            //   Title: "Live Chat Support",
            //   Description: "",
            //   Image: "",
            // }
        ]
    }];
}

export default getFeatureList;